.modal-overlay {
  position: fixed;
  top: -52px;
  left: -83px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 3;
  pointer-events: none;
}

.modal-content {
  width: 540px;
  background: #fff;
  padding: 16px 32px 36px 32px;
  pointer-events: auto;
  border-radius: 4px;
  box-shadow: 5px 16px 25px 11px rgba(44, 64, 90, 0.08);
  border: 1px solid rgba(44, 64, 90, 0.15);
}

.explore-button {
  height: 46px;
  padding: 12px 32px;
  background: #2C405A;
  color: white;
  border: none;
  border-radius: 6px;
  width: 476px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}

.explore-href {
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
}

.explore-href:hover {
  color: #FFF;
}

.custom-title {
  color: #212529;
  font-size: 24px;
  font-weight: 600;
  padding-top: 16px;
}

.custom-body {
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  padding-bottom: 28px;
}
