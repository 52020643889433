@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
$app-body-color: #ecf5fd;
// $my-primary-color: #1b2838;
$my-primary-color: rgb(44, 64, 90);
$my-secondary-color: rgb(110, 192, 187);

$spacer:   1rem !default;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  outline: none !important;
  box-shadow: none !important;
}

body {
  // background-color: $app-body-color !important;
  background-color: rgb(243, 243, 243);
  margin: 0;
  color: $my-primary-color;
  // overflow: -webkit-paged-y;
  //overflow-y: hidden;
  height: 100vh;
}

// Hide scrollbar Chrome, Safari, Opera
body::-webkit-scrollbar {
  display: none;
}

// Hide scrollbar Firefox
html {
  scrollbar-width: none;
}

.btn-primary {
  background-color: $my-primary-color;
  border-color: $my-primary-color;
}

.btn.btn-primary:disabled {
  background-color: rgba(182, 196, 214, 0.65);
  border-color: rgba(182, 196, 214, 0.65);
  // opacity: 65%;
}

// .btn-primary:hover {
//   background-color: #5C8FBF;
//   border-color: #5C8FBF;
// }

.page-item.active .page-link {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #1C7087 !important;
  opacity: 0.65;
}

.filter-buttons .page-link {
  border-radius: 4px;
}

.page-link {
  color: $my-primary-color;
}

.nav-link {
  color: $my-primary-color;
  border-color: #e9ecef #e9ecef #dee2e6 !important;
}

.dropdown-toggle::after {
  display: none;
}

select.form-control {
  width: 10rem;
}

.form-group {
  margin: 5px 0 5px 0;
}

span.page-link:hover {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

tspan {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

li.filter-title span {
  color: #2c405a !important;
  font-weight: 700;
  min-width: 7rem;
  margin-right: 0.25rem;
  background-color: #f3f3f3 !important;
}

.react-datepicker__input-container input {
  color: #6c757d;
  height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #333 !important;
  padding: 0.25rem 0.5rem;
  margin-top: -3px;
  margin-right: 0.45rem;
}

.form-control {
  display: inline !important;
  border-bottom: 1px solid #333;
}
.form-control:focus {
  display: inline !important;
  border-bottom: 1px solid #2D6999 !important;
}

.form-control[readonly] {
  background-color: white;
  color: #adb5bd;
  border-bottom: 1px solid #adb5bd;
}

.form-control[readonly]::placeholder {
  color: #adb5bd;
}

.read-only {
  color: #adb5bd;
}

#read-only {
  background-color: white;
  color: #adb5bd;
  border-bottom: 1px solid #adb5bd;
}

#sortby-select {
  padding-left: 8px !important;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
}

a {
  color: #007bff;
  text-decoration: none; /* no underline */
  font-weight: 400;
  // opacity: 0.65;
}

.adminOwnFunds {
  color: #2c405a !important;
  text-decoration: none; /* no underline */
  font-weight: 400;
  padding: 8px
}

.admin {
    color: #56BDB6 !important;
    text-decoration: none; /* no underline */
    font-weight: 400;
    opacity: 0.95;
}
.admin:hover {
  color: #006A87 !important;
  text-decoration: none; /* no underline */
  font-weight: 400;
}
.admin.btn-link:hover {
  color: #006A87 !important;
  text-decoration: none; /* no underline */
  font-weight: 400;
}
// .adminSelect:hover {
//   background-color: red !important;
//   color:rgb(243, 243, 243);
// }

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882A8 inset;
}

a:hover {
  color: #43948F;
  text-decoration: none;
}

.input-mobile {
  margin-bottom: 0.5rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background-color: white;
  display: block !important;
}

// label {
//   display: block !important;
// }

@media only screen and (max-width: 1000px) {
  body {
    background-color: white !important;
  }
}

// @media only screen and (min-width: 1001px) and (max-width: 4000px) {
  .admin-input-color, #read-only {
    background-color: rgb(255, 255, 255) !important;
  }
// }

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

input[type='text'],
textarea {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 0px;
}

select {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 0px !important;
}

th.position-relative {
  cursor: pointer;
}

.highchdarts-root {
  max-width: 96%;
  display: inline-block;
}

.pass-reg-row {
  max-width: 80% !important;
  display: flex;
  align-items: center;
}

.datepicker-credit-investor {
  margin: 0 !important;
  padding: 0 !important;
}

.modal-header,
.modal-footer {
  background-color: #fafbfc !important;
  padding:12px 48px,
}
.modal-body {
  padding:24px 48px 16px !important;
}

.btn-link {
  text-decoration: none !important;
  opacity: 0.65;
  color: #007bff !important;
}

.admin.btn-link {
  text-decoration: none !important;
  opacity: 0.95;
  color: #56BDB6 !important;
  font-weight: 400;
}

.feeAccounts.btn-link {
  text-decoration: none !important;
  opacity: 0.75;
  color: #56BDB6 !important;
}
.feeAccounts.btn-link:hover {
  text-decoration: none !important;
  color: #006A87 !important;
}

.link-btn {
  text-decoration: none !important;
  color: #007bff !important;
  opacity: 0.8;
}

.input, .mx-auto, .form-control, .form-control-sm{
  padding-left: 0px !important;
}

input,
select:not(.round-blue) {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  // border-bottom: 1px solid #333 !important;
  border-radius: 0 !important;
}

.react-datepicker__input-container input {
  max-width: 100px !important;
}

.react-datepicker__input-container .datePicker-filter {
  max-width: 113px !important;
  font-size: 16px;
  padding-left: 0px !important;
}

.app-navbar {
  img {
    border-radius: 0px;
  }
}

.pb-6 {
  padding-bottom: ($spacer * 4) !important;
}
.pb-32 {
  padding-bottom: ($spacer * 2) !important;
}
.pb-36 {
  padding-bottom: ($spacer * 2.25) !important;
}
.pt-32  {
  padding-top: ($spacer * 2) !important;
}

.dropdown-item:hover {
  background-color: #5C8FBF;
  color: white;
}

.search-icon {
  position: absolute;
  margin-left: 300px;
}

#graphicsPeriodSelect {
  padding-left: 8px !important;
}
.datePicker {
  padding-left: 8px !important;
}

.search-input {
  padding-left: 8px !important;
}

.modal-body {
  padding-bottom: 48px !important;
  padding-top: 32px !important;
}

.editUser.modal-body {
  padding-top: 8px !important;
}

.modal-dialog.modal-lg.commissions {
  width: 600px;
}

.modal-dialog.modal-lg.modal-filtered {
  width: 600px;
}

.modal-dialog.so-modal {
  width: 776px;
}

.invalid-img.is-invalid {
  background-size: 12px 12px;
}

.modal-filtered .modal-content {
  height: 829px;
}

.modal-dialog.modal-lg.settings {
  width: 560px;
}

.ordersModalSize {
  max-width: 700px;
}

.fa-users.fa-lg, .fa-hand-holding-dollar, .fa-user-tie, .fa-percent, .fa-desktop {
  color: #2C405A !important;
}

.licence {
  padding: 20px 27px;
}

.shadow-active-journal {
  bottom: 0;
  left: 0%;
  position: fixed;
  right: 0;
  top: 0%;
  z-index: 700;
  background: rgba(9, 30, 66, 0.54);
}

.shadow-active-sso {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 700;
  background: #f3f3f3;
}

.sidebar-show .shadow-active-journal-icon{
  margin-left: 115px;
  // transition: 0.3s;
}

.sidebar-show .shadow-active-journal-text{
  margin-left: 80px !important;
  // transition: 0.3s;
}

.shadow-journal {
  transition: opacity 1000ms ease;
  opacity: 1;
}

// .form-check .form-check-input:checked~.form-check-label::before{
//   background-color:red !important;
// }
// .form-check-input:checked~.form-check-label::before{
//   color: #fff !important;
//   background-color:red !important;
// }

// .custom-control-label:before{
//   background-color:white;
// }
// .custom-control-input {
//   border-radius: 0px !important;
// }
// .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
//   background-color: #52A7A1;
//   margin-top: '5px';
//   border-color: #52A7A1;
// }


// input[type=checkbox]{
//   display:none;
// }
// label{
//   cursor:pointer;
//   display:inline-block;
//   width:200px;
//   padding:5px 20px 5px 5px;
//   position:relative;
// }
// label:after{
//   content:'';
//   display:block;
//   position:absolute;
//   width:15px;
//   height:15px;
//   border:1px solid blue;
//   border-radius: 3px;
//   top:8px;
//   right:5px;
//   background-color:#fff;
// }
// // input[type=checkbox]:checked + label{
// //   background-color: #aaa;
// // }
// input[type=checkbox]:checked + label:after{
//   background-color: blue;
// }

.ellipsis-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.h-60px > a {
  margin: 0 auto !important;
  margin-top: 6px !important;
  position: relative !important;
  margin-left: 11px !important;
  text-decoration: none;
}

.nav-item .ml-navbar {
  width: 60px;
}

.pamm-navbar-exit {
  padding-top: 16px;
  padding-right: 32px !important;
}

.lang-dropdown .dropdown-menu.show.dropdown-menu-right {
  min-width: 8.5rem;
}

.lang-dropdown .dropdown-menu.show.dropdown-menu-right > a {
  padding-left: 1rem;
}

.lang-dropdown .dropdown-toggle.nav-link {
  padding-right: 16px !important;
  margin-right: 30px;
}

/* Скрыть стандартные полосы прокрутки */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Установить цвет фона */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Установить цвет полосы прокрутки */
::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 3px;
}

/* Установить цвет полосы прокрутки при наведении */
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Установить тонкую ширину полосы прокрутки */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.highcharts-admin div[data-highcharts-chart] {
  overflow-x: auto !important;
}

.highcharts-mobile div[data-highcharts-chart] {
  overflow: inherit !important;
}

.text-danger {
  color: #CC0000 !important;
}
 
.form-control.is-invalid {
  border-color: #CC0000 !important;
}

.select-calculation-profit {
  width: 100% !important;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  appearance: none;
  background-image: url('../src/icons/calculation-profit.svg'); 
  background-repeat: no-repeat;
  background-position: right center;
  border-bottom: none !important;
  padding-right: 25px !important;
}

.select-calculation-profit:focus {
  border-bottom: none !important;
}
   
