.adminRegistration {
  position:absolute;
  width: 402px;
  left: 98%; 
  padding: 16px 15px 24px 20px;
  background: white;
  border-radius: 4px;
  border: 1px solid #DADADA;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  z-index: 1000;
}

.masterRegistration {
  position: fixed;
  top: 31%; 
  left: 58%; 
  padding: 16px 15px 24px 20px;
  background: white;
  border-radius: 4px;
  border: 1px solid #DADADA;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  z-index: 1000;
}

.frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;
}

.passwordRequirements {
  align-self: stretch;
  color: #2C405A;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.requirementsList {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.requirementItem {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.ellipse {
  width: 7px;
  height: 7px;
  border-radius: 9999px;
}

.requirementText {
  color: #2C405A;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.requirementSpecialCharacter {
  color: #2C405A;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding-left: 8px;
}


.specialCharacterRequirement {
  align-self: stretch;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
