.app-login-page > .card {
  width: 18rem !important; }

.app-login-page > .container {
  height: calc(100vh - 58px); }

.app-navbar.navbar {
  height: 58px;
  padding: 0;
  background-color: #2C405A !important; }

.navbar-content-align {
  width: 70px;
  flex-shrink: 0;
  cursor: pointer;
  justify-content: center !important;
  align-items: center !important; }

.app-navbar .navbar-border {
  border-right: 1px solid #3E5068; }

.app-navbar .navbar-brand {
  margin: 0; }

.app-navbar img {
  border-radius: 10px; }

.app-navbar a {
  text-decoration: none; }

.app-navbar .navbar-user .dropdown-toggle::after {
  margin-top: 0.5rem; }

.app-navbar .navbar-user .dropdown-menu {
  padding: 0; }

.app-navbar .dropdown-divider {
  margin: 0; }

.app-navbar > div {
  height: 58px; }

.app-nav-bar > a > img {
  margin-top: 1px; }

.lang-dropdown .dropdown-toggle::after {
  color: white; }

.app-sidebar {
  box-shadow: inset -3px 0 7.5px 0 rgba(15, 16, 16, 0.17);
  background-color: #2c405a;
  width: 286px;
  height: calc(100vh - 58px);
  transition: width 0.3s;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  overflow: hidden; }

.ml-navbar {
  margin-left: 11px !important; }

.licence {
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.nav-item-styles {
  background-color: #30527A; }

.progress-title {
  letter-spacing: 1px;
  margin: auto;
  color: #8dabc4;
  padding-bottom: 25px; }

.progress-bar {
  width: 190px;
  margin: 0;
  font-size: 0.8rem; }

.app-sidebar-hidden {
  width: 70px; }

.app-sidebar-opacity-zero {
  opacity: 0; }

.app-sidebar-opacity {
  opacity: 1; }

.app-sidebar .w-90 {
  width: 90%; }

.app-sidebar a {
  text-decoration: none; }

.progress-purple .progress-bar {
  background-color: #ab7df6; }

.progress-aqua .progress-bar {
  background-color: #26c1c9; }

.progress {
  border-radius: 10rem !important; }

.app-rightbar {
  transition: width 0.3s, min-width 0.3s, opacity 0.3s, transform 0.3s;
  background-color: white;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 1000;
  flex-shrink: 0;
  height: calc(100vh - 58px);
  position: absolute;
  top: 0;
  right: 0;
  direction: ltr;
  width: 33%;
  min-width: 300px; }

.rightbar-scroll-right {
  direction: ltr; }

.rightbar-hidden {
  transform: translateX(100%); }

.app-accordion .accordion {
  transition: height 0.3s ease-out; }

.app-accordion .my-show {
  overflow: hidden; }

.app-accordion .accordion-header {
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between; }

.app-accordion .icon {
  transition: transform 0.3s ease-out; }

.app-accordion .icon-rotate {
  transform: rotate(90deg); }

.app-accordion.card {
  border-radius: 0; }

.shadow-active {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 700;
  background: rgba(9, 30, 66, 0.54); }

.shadow {
  transition: opacity 1000ms ease;
  opacity: 1; }

.app-table {
  background-color: white;
  border: 1px solid #C5DBEA;
  border-radius: 5px;
  border-spacing: 0;
  border-collapse: inherit;
  margin-bottom: 0 !important; }
  .app-table .table-tr_color {
    color: #8dabc4; }
  .app-table th {
    border-top: none !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    border-bottom: none !important; }
  .app-table td {
    border-color: #C5DBEA; }
  .app-table tr:hover {
    border-left: 3px solid #0093ee; }
  .app-table thead > tr:first-child:hover {
    border-left: none; }

table.app-table {
  text-align: center;
  white-space: nowrap; }

table.app-table thead {
  font-size: 11px; }

table.app-table tbody {
  font-size: 13px; }

table.with-settings th:last-child span {
  margin-right: 50px; }

table.with-settings th:last-child svg {
  position: relative;
  left: -50px; }

table.with-settings td:last-child > * {
  margin-right: 50px; }

.dropleft .dropdown-toggle::before {
  display: none; }

table {
  position: relative; }

.thead-settings {
  position: absolute;
  top: 10px;
  right: 10px; }

.search-input_style {
  border: none;
  padding-bottom: 1px;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  max-width: 100px !important;
  width: 50px !important;
  min-width: 20px !important;
  color: #8dabc4;
  font-size: 11px;
  outline: none; }

.search-input_style::placeholder {
  color: #8dabc4; }

.search-input_style:focus {
  padding-bottom: 0;
  border-bottom: 1px solid #8dabc4;
  color: black; }

.breadcrumb {
  color: #a8c6df;
  background-color: inherit !important;
  padding: 0; }
  .breadcrumb .breadcrumb-item {
    padding: 0; }
    .breadcrumb .breadcrumb-item a {
      text-decoration: none; }
    .breadcrumb .breadcrumb-item span {
      color: #3f536e;
      font-size: 0.9rem; }
  .breadcrumb .breadcrumb-item::before {
    content: '';
    padding: 0;
    margin: 0; }
  .breadcrumb svg, .breadcrumb span {
    margin-right: 10px;
    color: #a8c6df; }
  .breadcrumb li {
    display: flex;
    align-items: center; }

.app-tabs {
  background-color: white;
  margin-bottom: 20px;
  border-radius: 5px; }
  .app-tabs .tab-content {
    padding: 0.5rem 0.5rem;
    border: 1px solid rgba(183, 210, 229, 0.8);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .app-tabs .nav-tabs {
    background-color: #fafbfc;
    border: 1px solid rgba(183, 210, 229, 0.8);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .app-tabs .nav-tabs .nav-link {
    color: #8dabc4;
    border: 1px solid rgba(183, 210, 229, 0.8);
    border-top: none;
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .app-tabs .nav-tabs .nav-link:first-child {
    border-top-left-radius: 0.25rem; }
  .app-tabs .nav-tabs a.active {
    color: #0093ee; }

.app-tabs-1 {
  background-color: white;
  margin-bottom: 20px;
  border-radius: 5px; }
  .app-tabs-1 .tab-content {
    padding: 0.5rem 0.5rem;
    border: 1px solid rgba(183, 210, 229, 0.8);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .app-tabs-1 .nav-tabs {
    background-color: #fafbfc;
    border: 1px solid rgba(183, 210, 229, 0.8);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .app-tabs-1 .nav-tabs .nav-link {
    color: #8dabc4;
    border: 1px solid rgba(183, 210, 229, 0.8);
    border-top: none;
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .app-tabs-1 .nav-tabs .nav-link:first-child {
    border-top-left-radius: 0.25rem; }
  .app-tabs-1 .nav-tabs a.active {
    color: #0093ee; }
  .app-tabs-1 .nav-tabs .nav-link.active, .app-tabs-1 .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #C5DBEA #C5DBEA #fff; }

.app-tabs-2 {
  margin-bottom: 20px; }
  .app-tabs-2 .nav-tabs .nav-link.active, .app-tabs-2 .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #C5DBEA #C5DBEA #fff; }

.app-card {
  margin-bottom: 20px; }
  .app-card .card-body {
    padding: 0.5rem; }

.app-settings {
  transition: height 0.5s ease-in-out; }
  .app-settings span {
    color: #324766 !important;
    font-weight: 500;
    text-transform: none; }
  .app-settings .icon {
    transition: transform 0.3s ease-out; }
  .app-settings .icon-rotate {
    transform: rotate(90deg); }
  @media (min-width: 600px) {
    .app-settings .resize_inputs {
      flex-direction: column !important; } }
  @media (min-width: 1200px) {
    .app-settings .resize_inputs {
      flex-direction: row !important; } }
  .app-settings .wrapper {
    padding: 0.75rem 1.25rem; }

.app-settings-border {
  border: 1px solid #C5DBEA;
  border-radius: 5px; }

body {
  transition: background-color 0.5s; }

body > .container-fluid {
  padding: 0 !important; }

.h-60px {
  height: 60px; }

.cursor_pointer {
  cursor: pointer; }

.horizontal-line {
  opacity: 0.1;
  background-color: #ffffff;
  margin-top: 0;
  margin-bottom: 0; }

.br-5 {
  border-radius: 5px !important; }

.zindex2 {
  z-index: 2 !important; }

.nowrap {
  white-space: nowrap !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.dropleft .dropdown-toggle::before {
  display: none; }

.os-content-glue {
  width: 100% !important; }

.t4b-bg-dark {
  background-color: #2C405A; }

.t4b-bg-light {
  background-color: #30527A; }

.t4b-bg-lightgreen {
  background-color: #81c926; }

.t4b-bg-logo-middle {
  background-color: #2b778d; }

.t4b-bg-logo-light {
  background-color: #6fc1bc; }

.t4b-text {
  color: #324766; }

.t4b-text-light {
  color: #3f536e; }

.t4b-text-gray {
  color: #8dabc4 !important; }
